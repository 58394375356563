.header-box[data-v-25203680] {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.top_right_btns[data-v-25203680] {
  float: right;
  margin-right: 15px;
}
.top_right_btns i[data-v-25203680] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.el-select[data-v-25203680] {
  margin: 0px;
}
.checkRate[data-v-25203680] {
  cursor: pointer;
}
.checkRate[data-v-25203680]:hover {
  color: var(--themeColor, #17a2b8);
}
[data-v-25203680] .el-radio__label {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}